<template>
    <div>
        <v-parallax
            src="../assets/images/slide1.jpg"
            :height="($vuetify.breakpoint.smAndUp)?250:180"
        >
            <v-container class="mt-auto">
                <v-row>
                    <v-col cols="12">
                        <h1 class="text-sm-h4 text-h5 font-weight-thin mb-4">
                            <v-icon :size="($vuetify.breakpoint.smAndUp)?65:45">
                                mdi-cart
                            </v-icon> <br>
                            Tu carrito
                        </h1>
                    </v-col>
                </v-row>
            </v-container>
        </v-parallax>

        <v-container class="py-15">
            <v-row v-if="JSON.stringify(cart)!='{}'">
                <v-col cols="12" md="4">
                    <v-card height="100%">
                        <v-card-title class="primary">
                            Resumen
                        </v-card-title>
                        <v-card-text class="pt-5">
                            <p>Productos: {{ count }}</p>
                            <div>
                                <strong>Total: </strong>
                                <strong class="text-h5">{{ total }} Bs.</strong>
                            </div>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="12" md="8">
                    <v-card>
                        <v-card-title>&nbsp;</v-card-title>
                        <v-card-text class="pa-0">
                            <v-simple-table>
                                <template v-slot:default>
                                <thead>
                                    <tr>
                                        <th class="text-left" style="min-width: 250px;">
                                            Producto
                                        </th>
                                        <th class="text-left">
                                            Cantidad
                                        </th>
                                        <th class="text-left" style="min-width: 80px;">
                                            total
                                        </th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(product, j) in cart" :key="j">
                                        <td>
                                            <v-avatar size="60" tile>
                                                <v-img :src="product.img" contain></v-img>
                                            </v-avatar>
                                            {{ product.name }} <small v-if="product.complement!=''">({{ product.complement }}) &nbsp;</small>
                                            <small class="primary--text">{{ product.price }} Bs.</small>
                                        </td>
                                        <td>
                                            <div class="d-flex align-center">
                                                <v-btn color="primary" small min-width="30" class="rounded-r-0 px-2" @click="$store.dispatch('cart/updateItem', [j, 'minus'])" :disabled="product.quantity<=1">
                                                <!-- <v-btn color="primary" small min-width="30" class="rounded-r-0 px-2" @click="()=>{product.quantity-=1}" :disabled="product.quantity<=1"> -->
                                                    <v-icon dark>
                                                    mdi-minus
                                                    </v-icon>
                                                </v-btn>
                                                <v-sheet
                                                    height="28"
                                                    width="60"
                                                    class="sheet-cant"
                                                >
                                                    {{ product.quantity }}
                                                </v-sheet>
                                                <v-btn color="primary" small min-width="30" class="rounded-l-0 px-2" @click="$store.dispatch('cart/updateItem', [j, 'plus'])">
                                                    <v-icon dark>
                                                    mdi-plus
                                                    </v-icon>
                                                </v-btn>
                                            </div>
                                        </td>
                                        <td>{{ product.quantity * product.price }} Bs.</td>
                                        <td>
                                            <v-tooltip top>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn
                                                        icon
                                                        small
                                                        @click="$store.dispatch('cart/removeItem', j)"
                                                        color="error"
                                                        v-bind="attrs"
                                                        v-on="on"
                                                    >
                                                        <v-icon>mdi-trash-can-outline</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>Quitar del carrito</span>
                                            </v-tooltip>
                                        </td>
                                    </tr>
                                </tbody>
                                </template>
                            </v-simple-table>
                        </v-card-text>
                        <v-card-actions class="py-10 px-5">
                            <v-spacer></v-spacer>
                            <v-btn
                                color="primary"
                                large
                                rounded
                                class="pl-4"
                                to="/finalizar-compra"
                            >
                                Confirmar pedido
                                <v-icon
                                    right
                                >
                                    mdi-chevron-right
                                </v-icon>
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>

            <div class="pa-4 text-center" v-else>
                <div class="text-center mt-5 mb-10">
                    <v-icon color="grey darken-1" size="150">mdi-shopping-outline</v-icon>
                    <div class="mt-5 font-weight-thin">Tu carrito está vacío.</div>
                </div>
                <v-btn
                    color="error"
                    to="/tienda"
                >Seguir comprando</v-btn>
            </div>
        </v-container>
    </div>
</template>
<script>
export default {
    data() {
        return {
            
        }
    },
    computed: {
        count () {
            return this.$store.state.cart.count;
        },
        cart () {
            return this.$store.state.cart.cart;
        },
        total () {
            return this.$store.state.cart.total;
        }
    },
}
</script>
<style lang="scss">
.sheet-cant {
    font-size: 18px;
    line-height: 28px;
}
</style>